/* ==================
	5. Page Banner
=================== */
.page-banner{
	background:$light;
    background-repeat: no-repeat;
	background-size:cover;
	position: relative;
    overflow: hidden;
	height: 420px;
	width: 100%;
	z-index: 1;
	
	@include respond('tab-port'){
		height: 320px;
	}
	@include respond('phone-land'){
		height: 280px;		
	}
	.container{
		display: table;
		height: 100%;
		
	}
	.page-banner-entry{
		height: 420px;
		vertical-align: middle;
		display: table-cell;
		padding-top: 40px;
		padding-bottom: 60px;
		
		@include respond('tab-port'){
			height: 320px;
			padding-bottom: 30px;			
		}
		@include respond('phone-land'){
			height: 280px;
		}
	}
	h1 {
		font-weight: 700;
		font-size: 72px;
		margin-bottom: 20px;
		color: #1f2278;
		text-transform: capitalize;
		@include respond('laptop-small'){
			font-size: 62px;
		}
		@include respond('tab-port'){
			font-size: 52px;	
		}
		@include respond('phone-land'){
			font-size: 42px;	
		}
	}
	&:after{
		content: "";
		background-image: url(../../../images/banner/shape.png);
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: contain;
		z-index: -1;
	}
	&.banner-lg{
		height: 550px;
		.page-banner-entry{
			height: 550px;
		}
		@include respond('tab-port'){
			height: 450px;
			.page-banner-entry{
				height: 450px;
			}
		}
		@include respond('phone-land'){
			height: 400px;
			.page-banner-entry{
				height: 400px;
			}
		}
	}
	&.banner-md{
		height: 450px;
		.page-banner-entry{
			height: 450px;
		}
	}
	&.banner-sm{
		height: 300px;
		.page-banner-entry{
			height: 300px;
		}
	}
	
	.pt-img1{
		left: 15%;
		bottom: 50px;
		z-index: 1;
	}
	.pt-img2{
		left: 30px;
		top: 100px;
	}
	.pt-img3{
		right: 8%;
		bottom: 30%;
	}
}
/* ==================
	6. Breadcrumb
=================== */
.breadcrumb-row{
	box-shadow: 0px 5px 30px 0px rgba(86, 90, 207, 0.3);
	font-family: "Montserrat", sans-serif;
    background-color: #565acf;
    padding: 12px 30px;
    border-radius: 10px;
    display: inline-block;
	@include respond('phone-land'){
		padding: 10px 24px;
	}
	ul {
		background:transparent;
		padding: 0;
		justify-content: center;
		margin:0;
		li {
			padding: 0;
			display: inline-flex;
			font-size: 16px;
			font-weight: 500;
			align-items: center;
			a{
				color: $light;
				display: flex;
				align-items: center;
	
				svg{
					margin-right: 10px;
					transform: translateY(-2px);
				}
			}
			@include respond('laptop-small'){
				font-size: 14px;
			}
		}
	}
}
.breadcrumb-item + .breadcrumb-item{
	padding-left: 15px;
	color:$light;
	&::before{
		color: #fff;
		content: "/";
		font-size: 22px;
		padding-right: 15px;
		opacity: 0.4;
		font-weight: 300;
		line-height: 22px;
		margin-top: 5px;
	}
	@include respond('laptop-small'){
		padding-left: 10px;
		&::before {
			font-size: 18px;
			padding-right: 10px;
			line-height: 18px;
		}
	}
}
